// 首页
export const PATH_ROOT = '/';
export const PATH_INDEX = '/index';
export const PATH_HOME = '/home';
export const PATH_UNIT_PLAY = '/unitPlay';
export const PATH_UNIT_WORD = '/unitWord';
export const PATH_READ_WORD = '/readWord';
export const PATH_LISTERNER_WORD = '/listernerWord';
export const PATH_PAY = '/orderPay';
// user
export const PATH_LOGIN = '/login';
export const PATH_REGISTER = '/register';
export const DOWN_LOAD = '/downLoad';

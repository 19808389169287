import request from '../request';
const _model = '/book';
export default {
  // 开发环境登陆
  wxJsApiInit(params) {
    return request.get(`${_model}/page`, params);
  },
  wxSignature(params) {
    return request.get(`/weixin/signature`, params);
  },
  weixinH5Login(data) {
    return request.post('/weixin/h5/login', data);
  },
  weixinLoginUrl(data) {
    return request.get('/weixin/login/url', data);
  }
};
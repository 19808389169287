import { lazyComponent } from '@/utils';
import * as path from './routePath';
export default [
  {
    path: path.PATH_HOME,
    meta: { title: '首页', keep: true },
    component: () => import('@/pages/index/index.vue')
  },
  {
    path: path.PATH_UNIT_PLAY,
    meta: { title: '单元详情', keep: true },
    component: () => import('@/pages/unitPlay/index.vue')
  },
  {
    path: path.PATH_UNIT_WORD,
    meta: { title: '单元练习', keep: true },
    component: () => import('@/pages/unitWord/index.vue')
  },
  {
    path: path.PATH_READ_WORD,
    meta: { title: '单元跟读', keep: true },
    component: () => import('@/pages/unitWord/read.vue')
  },
  {
    path: path.PATH_LISTERNER_WORD,
    meta: { title: '听写练习', keep: true },
    component: () => import('@/pages/unitWord/listerner.vue')
  },
  {
    path: path.PATH_PAY,
    meta: { title: '课程支付', keep: true },
    component: () => import('@/pages/orderPay/index.vue')
  }
];

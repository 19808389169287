
import Storage from '@/utils/Storage';
import config from '@/config/index';
import {os} from './src/tools';
import qs from 'qs';
import store from '@/store';
import operationApi from '@/api/src/operation';
// 操作localStorage
export const loc = new Storage({ locKey: 'localStorage' });
// 操作 sessionStorage
export const sLoc = new Storage();
export function replaceUrl (exp, val) {
  const url = window.location.href.replace(window.location.origin, '').replace(exp, val);
  window.history.replaceState('', document.title, url);
}
export function param2Obj (url) {
  url = url || window.location.search;

  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  );
}
export function getUrlAppId () {
  const url = window.location.hash;
  const query = qs.parse(url.split('?')[1]);
  loc.set('LETS_APP_ID', query.appId);
  return query.appId || config.appId;
}
export function getVersion() {
  const url = window.location.hash;
  const query = qs.parse(url.split('?')[1]);
  loc.set('version',query.vs || 'HUAWEI');
  return query.vs || 'HUAWEI';
}
export function isAppTest() {
  const vs = loc.get('version');
  const isTest = vs.indexOf('_') > -1;
  return (!os.isWechat && isTest) || os.isWechat;
}
export function getImei () {
  let deviceUuid = loc.get('device_uuid');
  let appId = getUrlAppId();
  return deviceUuid || appId;
}
export const fetchOperationLog = async (title) => {
  let appInfo = loc.get('appInfo', {});
  return await operationApi.operationLog({
    appInfoId: appInfo.id,
    content: getImei(),
    bookId: store.getters.currentBook && store.getters.currentBook.id,
    page: title || '没点到',
    unitId: store.getters.currentUnit && store.getters.currentUnit.id
  });
};
'use strict';
import store2 from '../../store2';
import { app, course } from '../mutationType';
import * as storageKey from '@/config/static';
// import { codes } from '@/api/request/requestCode';
import bookApi from '@/api/src/book';
// import { alertConfirm } from '@/utils/src/utilsAlert';
// import router from '@/router';
// import * as path from '@/router/routes/routePath';
import * as auth from '@/utils/auth';
import { tabbarList } from '@/model/dict';
export default {
  namespaced: true,
  state: {
    userInfo: store2.get(storageKey.STORAGE_USER_INFO) || { userAvatar: null },
    book: store2.get(storageKey.STORAGE_CURRENT_BOOK) || {},
    mainTab: store2.get(storageKey.STORAGE_HOME_TAB) || 'Home',
    unitIndex: store2.get(storageKey.STORAGE_UNIT_INDEX) || 0,
    unitList: [],
    wordList: [],
    pageContentList: store2.get(storageKey.STORAGE_PAGE_CONTENT) || []
  },
  actions: {
    appUpdateUserInfo({ commit }, userInfo) {
      // isUnauthRequest 传入true，则请求都会被resolve，用于进入项目就更新用户信息，处理缓存
      commit(app.UPDATE_USER_INFO, userInfo);
    },
    setCurrentBook({ commit }, book) {
      console.log(book);
      commit(course.CURRENT_BOOK, book);
    },
    setHomeTab(ctx, tab) {
      const item = tabbarList.find(item => item.value === tab);
      auth.fetchOperationLog(item.name);
      ctx.commit(course.HOME_TAB, tab);
    },
    setUnitIndex({ commit }, index) {
      commit(course.UNIT_INDEX, index);
    },
    setUnitList({ commit }, list) {
      commit(course.UNIT_LIST, list);
    },
    setWordList({ commit }, list) {
      console.log('setWordList', list);
      commit(course.WORD_LIST, list);
    },
    setPageContent({ commit, state }, index) {
      console.log(state);
      return bookApi[`get${!index ? 'First' : ''}UnitContent`]({
        unitId: state.unitList[index].id
      }).then(res => {
        const list = res.map(_item => {
          const size = window.innerWidth / +_item.width;
          const pageContentList =
            (_item.pageContentList &&
              _item.pageContentList.map(item => {
                return {
                  ...item,
                  style: {
                    top: +item.top * size - 5 + 'px',
                    left: +item.leftNum * size - 5 + 'px',
                    height: +item.height * size + 10 + 'px',
                    width: +item.width * size + 10 + 'px',
                    transform: `rotate(${+item.angle}deg)`
                  }
                };
              })) ||
            [];
          return {
            ..._item,
            pageContentList
          };
        });
        commit(course.PAGE_CONTENT_LIST, list);
      });
      // .catch(res => {
      //   if(res.status==501){

      //   }

      //   return Promise.reject(res);
      // });
    }
  },
  mutations: {
    [app.UPDATE_USER_INFO](state: any, userInfo = null) {
      state.userInfo = userInfo;
      // if (!userInfo) {
      //   store2.remove(storageKey.STORAGE_USER_INFO);
      // } else {
      console.log(userInfo);
      store2.set(storageKey.STORAGE_USER_INFO, userInfo);
      // }
    },
    // 当前教材
    [course.CURRENT_BOOK](state: any, book = {}) {
      state.book = book;
      store2.set(storageKey.STORAGE_CURRENT_BOOK, book);
    },
    // 当前首页Tab
    [course.HOME_TAB](state: any, tab) {
      state.mainTab = tab;
      store2.set(storageKey.STORAGE_HOME_TAB, tab);
    },
    [course.UNIT_INDEX](state: any, index = {}) {
      state.unitIndex = index;
      store2.set(storageKey.STORAGE_UNIT_INDEX, index);
    },
    [course.UNIT_LIST](state: any, list = []) {
      state.unitList = list;
    },
    [course.WORD_LIST](state: any, list = []) {
      state.wordList = list;
    },
    [course.PAGE_CONTENT_LIST](state: any, list = []) {
      store2.set(storageKey.STORAGE_PAGE_CONTENT, list);
      state.pageContentList = list;
    }
  }
};

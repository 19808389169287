import Vue from 'vue';
import store from '@/store';
import router from '@/router/index';
import { projMode } from '@/config/index';
import '@/assets/js/flexible';
const varColor = require('@/styles/varHelper/_var.scss');
import '@/router/permission';
import App from './App.vue';
import message from '@/utils/src/message';
import apiBook from '@/api/src/book';
import * as auth from '@/utils/auth';
import * as filters from '@/filters';
import * as tool from '@/utils/src/tools';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.config.devtools = projMode !== 'productionn';
Vue.prototype.$msg = message;
for (const key in filters) {
  Vue.filter(key, filters[key]);
}
import './appInit';
Vue.config.productionTip = false;
Vue.prototype.$varColor = varColor;
tool.showDebugBuyUrl();

const getBookId = async version => {
  const appId = auth.getUrlAppId();
  const res = await apiBook.getAppInfo({ appId, version: version.toUpperCase() });
  auth.loc.set('appInfo', res);
};
const init = () => {
  const v = auth.getVersion();
  getBookId(v).then(res => {
    const vm = new Vue({
      router,
      store,
      // mounted() {
      //   window.addEventListener('beforeunload', e => {
      //     this.beforeunloadHandler(e);
      //   });
      // },
      // destroyed() {
      //   window.removeEventListener('beforeunload', e => {
      //     this.beforeunloadHandler(e);
      //   });
      // },
      // methods: {
      //   beforeunloadHandler(e) {
      //     e = e || window.event;
      //     if (e) {
      //       e.returnValue = '您是否确认离开此页面-您输入的数据可能不会被保存';
      //     }
      //     console.log('您是否确认离开此页面');
      //     return '您是否确认离开此页面-您输入的数据可能不会被保存';
      //   }
      // },
      render: h => h(App)
    }).$mount('#app');
  });
};
init();

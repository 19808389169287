export * from './dictionary';
export * from './static';
export * from './options';
export const projMode = process.env.VUE_APP_FLAG;
export const downAppUrl = 'https://bookplus.oss-cn-beijing.aliyuncs.com/apk/snj.apk';
const development = {
  host: 'http://testapi.lustudy.cn/v1',
  appId: '18DA1F73',
  payMoney: 9.9,
  ossHost: '',
  h5InfoCode: '4637909D',
  h5Id: 12
};
const test = {
  host: 'http://testapi.lustudy.cn/v1',
  appId: '18DA1F73',
  payMoney: 9.9,
  ossHost: '',
  h5InfoCode: '4637909D',
  h5Id: 12
};
const production = {
  host: 'https://api.lustudy.cn/v1',
  appId: '18DA1F73',
  payMoney: 9.9,
  ossHost: '',
  h5InfoCode: '4637909D',
  h5Id: 12
};
const CONFIG = {
  development,
  test,
  production
};
export default { uniBase: 'MzkzODIzNTk3Ng==', downAppUrl, ...CONFIG[projMode], appTitle: '一起学' };
